import * as payoutClientAPI from './api-specs-payout/client'

export * from './api-django'
export * from './api-models'
export * from './standards'
export * from './utils'

/**
 * Bulk Imports API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/bulk-import-api/definition}
 */
export * as bulkImportsAPI from './api-specs-acquisition/bulk-imports'

/**
 * Link Booster API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/link-booster-api/definition}
 */
export * as linkBoosterAPI from './api-specs-acquisition/link-booster'

/**
 * Client API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/client-api/definition}
 */
export const clientAPI = {
  ...payoutClientAPI,
}

/**
 * Client Back Office API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-bo-client-api/definition}
 */
export * as clientBackOfficeAPI from './api-specs-after-sale-experience/client-back-office'

/**
 * CustomerOrders API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-customer-orders-api/definition}
 */
export * as customerOrdersAPI from './api-specs-after-sale-experience/customer-orders'

/**
 * Petanque Client API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-petanque-client-api/definition}
 */
export * as petanqueClientAPI from './api-specs-after-sale-experience/petanque-client'

/**
 * Favorite API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/my-favorites-service-api/definition}
 */
export * as favoriteAPI from './api-specs-favorite/favorite'

/**
 * CookieConsent API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-cookie-consent-api/definition}
 */
export * as cookieConsentAPI from './api-specs-authnz/cookie-consent'

/**
 * Auth Legacy API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-legacy-api/definition}
 */
export type * from './api-specs-authnz/legacy-auth'
export * as authLegacyAPI from './api-specs-authnz/legacy-auth'

/**
 * Buyback API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-bm-api/definition}
 */
export * as buybackCustomerAPI from './api-specs-buyback/customer'
export * as buybackSellerAPI from './api-specs-buyback/seller'
export * as buybackStaffAPI from './api-specs-buyback/staff'
export * as buybackModelsAPI from './api-specs-buyback/models'

/**
 * Akeneo Catalog Access API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/catalog-akeneo-api/definition}
 */
export * as akeneoCatalogAccessAPI from './api-specs-catalog/akeneo-catalog-access'

/**
 * Buyback Catalog API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/catalog-buyback-api/definition}
 */
export * as buybackCatalogAPI from './api-specs-catalog/buyback-catalog'

/**
 * Catalog Access API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/catalog-access-api/definition}
 */
export * as catalogAccessAPI from './api-specs-catalog/catalog-access'

/**
 * Checkout - Cart API (Web)
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-cart-api/definition}
 */
export * as cartAPI from './api-specs-checkout/cart'

/**
 * Checkout API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/checkout-web-cart-api/definition}
 */
export * as checkoutAPI from './api-specs-checkout/checkout'

/**
 * Care Folder API - BM
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/customer-request-care-folder-internal-api/definition}
 */
export * as careFolderAPI from './api-specs-claim-management/care-folder'

/**
 * Repair API - BM
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/backrepair-internal-api/definition}
 */
export * as repairAPI from './api-specs-claim-management/repair'

/**
 * Customer Request API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/customer-request-internal-api/definition}
 */
export * as customerRequestAPI from './api-specs-claim-management/customer-request'

/**
 * CRM Service API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/crm-service-api/definition}
 */
export * as crmServiceAPI from './api-specs-crm-service/crm-service'

/**
 * Finance API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/finance-merchant-api/definition}
 */
export * as financeAPI from './api-specs-finance-tools/finance'

/**
 * Finance - Ccbm fee rules
 */
export * as financeCcbmFeeRulesAPI from './api-specs-finance-tools/finance-ccbm-fee-rules'

/**
 * Finance - Credit request API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/finance-credit-request-api/definition}
 */
export * as financeCreditRequestAPI from './api-specs-finance-tools/finance-credit-request'

/**
 * Seller Ledger API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/finance-seller-ledger-api/definition}
 */
export * as financeSellerLedgerAPI from './api-specs-finance-tools/finance-seller-ledger'

/**
 * Seller Compensation API
 */
export * as financeSellerCompensationsAPI from './api-specs-finance-tools/finance-seller-compensations'

/**
 * Finance - User permissions API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/finance-user-api/definition}
 */
export * as financeUserPermissionsAPI from './api-specs-finance-tools/finance-user-permissions'

/**
 * Help Center API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/help-center-api/definition}
 */
export * as helpCenterAPI from './api-specs-help-center/help-center'

/**
 * Insurances API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/insurance-internal-v1-api/definition}
 */
export * as insurancesAPI from './api-specs-insurance/insurances'

/**
 * Insurances2 API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/insurance-internal-v2-api/definition}
 */
export * as insurances2API from './api-specs-insurance/insurances2'

/**
 * Product List Page API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/landing-page-api/definition}
 */
export * as productListPageAPI from './api-specs-navigation-experience/product-list-page'

/**
 * Navigation API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/navigation-api/definition}
 */
export * as navigationAPI from './api-specs-navigation-experience/navigation'

/**
 * Merchant Shop details
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/merchant-shop-api/definition}
 */
export * as merchantShopAPI from './api-specs-navigation-experience/merchant-shop'

/**
 * Product API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/product-page-api/definition}
 */
export * as productAPI from './api-specs-navigation-experience/product'

/**
 * Balance Adjustments API
 */
export * as balanceAdjustmentsAPI from './api-specs-order-management/balance-adjustments'

/**
 * ManualRefunds API
 */
export * as manualRefundsAPI from './api-specs-order-management/manual-refunds'

/**
 * Orders API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/orders-internal-api/}
 */
export * as ordersAPI from './api-specs-order-management/orders'

/**
 * Payment API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-web-api/definition}
 */
export * as paymentAPI from './api-specs-payment/payment'

/**
 * Refund Payment API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-refund-api/definition}
 */
export * as refundPaymentAPI from './api-specs-payment/refund-payment'

/**
 * Payout Hyperwallet Admin API
 */
export * as hyperwalletAPI from './api-specs-payout/hyperwallet'

/**
 * Refund Payment API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/payment-refund-api/definition}
 */
export * as moneyOutAPI from './api-specs-payout/money-out'

/**
 * Admin reviews API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/reviews-bo-api/definition}
 */
export * as adminReviewsAPI from './api-specs-reviews/admin-reviews'

/**
 * Landing reviews API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product-landing}
 */
export * as landingReviewsAPI from './api-specs-reviews/landing-reviews'

/**
 * Seller reviews API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/seller}
 */
export * as sellerReviewsAPI from './api-specs-reviews/seller-reviews'

/**
 * Product reviews API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-id-reviews}
 */
export * as productReviewsAPI from './api-specs-reviews/product-reviews'

/**
 * All Reviews API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#tag/product/operation/get-reviews-products-reviews}
 */
export * as reviewsAPI from './api-specs-reviews/reviews'

/**
 * Recommendations API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/recommendation-v2-api/definition}
 */
export * as recommendationsAPI from './api-specs-search-reco/recommendations'

/**
 * Search API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/search-api/definition}
 */
export * as searchAPI from './api-specs-search-reco/search'

/**
 * Backoffice Banners API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-backoffice-banners-api/definition}
 */
export * as backOfficeBannersAPI from './api-specs-seller-backoffice/back-office-banners'

/**
 * Merchants API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-merchants-api/definition}
 */
export * as merchantsAPI from './api-specs-seller-backoffice/merchants'

/**
 * Merchants Orders API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-orders-api/definition}
 */
export * as merchantsOrdersAPI from './api-specs-seller-backoffice/merchants-orders'

/**
 * Recycling API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/recycling-api/definition}
 */
export * as recyclingAPI from './api-specs-recycling'

/**
 * Seller Opportunities
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-opportunities-api/definition}
 */
export * as sellerOpportunities from './api-specs-seller-backoffice/seller-opportunities'

/**
 * Seller Profile
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-profile-api/definition}
 */
export * as sellerProfileAPI from './api-specs-seller-backoffice/seller-profile'

/**
 * Seller Users API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-users-api/definition}
 */
export * as sellerUsersAPI from './api-specs-seller-backoffice/seller-users'

/**
 * Staff Sellers API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-staff-api/definition}
 */
export * as staffSellersAPI from './api-specs-seller-backoffice/staff-sellers'

/**
 * Seller Customer Care API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-after-sales-service-api/definition}
 */
export * as sellerCustomerCareAPI from './api-specs-seller-backoffice/seller-customer-care'

/**
 * Onboarding API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-self-onboarding-api/definition}
 */
export * as onboardingAPI from './api-specs-seller-onboarding/onboarding'

/**
 * Address API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/shipping-addresses-api/definition}
 */
export * as addressAPI from './api-specs-shipping/address'

/**
 * Carrier API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/shipping-addresses-api/definition}
 */
export * as carrierAPI from './api-specs-shipping/carrier'

/**
 * Backship Billing API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/shipping-backship-billing-admin-api/definition}
 */
export * as backshipBillingAPI from './api-specs-shipping/backship/billing'

/**
 * Collection Points API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/shipping-collection-points-api/definition}
 */
export * as collectionPointsAPI from './api-specs-shipping/collection-points'

/**
 * Listings API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/seller-merchants-api/definition}
 */
export * as listingsAPI from './api-specs-shipping/listings'

/**
 * Shippers API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/shipping-shippers-api/definition}
 */
export * as shippersAPI from './api-specs-shipping/shippers'

/**
 * Task Manager API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/task-manager-api/definition}
 */
export * as taskManagerAPI from './api-specs-task-manager/task-manager'

/**
 * Web branding API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/branding-web-api/definition}
 */
export * as brandingAPI from './api-specs-user-lifecycle/branding'

/**
 * ContentService API - Web
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/content-service-web-api/definition}
 */
export * as contentServiceAPI from './api-specs-content'

/**
 * Marketing Operation API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/marketing-operation-api/definition}
 */
export * as marketingOperationAPI from './api-specs-user-lifecycle/marketing-operation'

/**
 * Promotion codes API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/promotion-codes-api/definition}
 */
export * as promotionCodesAPI from './api-specs-user-lifecycle/promotion-codes'

/**
 * OAuth API
 */
export * as oauthAPI from './api-specs-identity/oauth'

/**
 * Mobile plan API
 */
export * as mobilePlanAPI from './api-specs-b2c-services/mobile-plan'

export * as modelComparisonAPI from './api-specs-model-comparison'

/**
 * Service request api
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/service-request-api/definition}
 */
export * as serviceRequestAPI from './api-specs-service-request'
